/* Component Over ride css changes */

/* Table Css */
.react-bootstrap-table-pagination-list {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

.page-link {
  margin: 0 4px !important;
  border-radius: 3px !important;
  /* color: #001737 !important; */
  /* font-size: 14px !important; */
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius) !important;
  border-bottom-left-radius: var(--bs-pagination-border-radius) !important;
}

.disabled>.page-link,
.page-link.disabled {
  color: var(--bs-pagination-disabled-color) !important;
  pointer-events: none !important;
  background-color: var(--bs-pagination-disabled-bg) !important;
  border-color: var(--bs-pagination-disabled-border-color) !important;
}

.dataTables_info {
  display: block;
  padding-top: 0.85em;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

@media print {
  .no-print {
    display: none !important;
  }
 
  .table td {
    font-size: 9px !important;
  }
  
  .table th {
    font-size: 10px !important;
    font-weight: 600 !important;
    color: #636161 !important;
  }

  .inv-image-height {
    height: 200px !important;
  }

}

.inv-image-height {
  height: 350px;
}

.stock-list-header-style {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #636161 !important;
}

.table-view-mode {
  cursor: pointer;
  color: #0652DD;
}

.positive-performance {
  color: #58ae25;
}

/* Profile Dropdown css */
.profile-header-icon {
  height: 36px;
  width: 36px
}

.profile-dropdown {
  position: absolute;
  margin: 0;
  top: 10px;
}

.datachart-nodata {
  margin: 126px 0px;
  font-family: inter, sans-serif;
  opacity: 0.55;
  text-anchor: middle;
  font-size: 30px;
  fill: rgb(127, 131, 139);
  height: 14px;
}

.box-layout {
  max-height: 300px;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.reminder-header {
  margin: 10px 0px;
  font-weight: bold;
}

.reminder-body {
  margin: 5px 0px;
}

.reminder-action-btn {
  font-weight: 800;
  margin-left: 30px;
}

.text-bold {
  font-weight: bold;
}

.switch-view-mode {
  font-weight: bold;
  margin-left: 4px;
}


/* Email field values css */
.email-wrapper {
  background-color: white;
  border-radius: 0.3rem;
}

.email-input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 10px;
  height: auto;
  padding: 0.2rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 8px;
  border: 2px solid #ebebeb;
  color: #565656;
}

.email-input:focus-within {
  border-color: cornflowerblue;
}

.email-input.email-has-error {
  border-color: tomato;
}

.email-error {
  margin: 0;
  font-size: 90%;
  color: red;
}

.email-tag-item {
  background-color: #dceefd !important;
  color: #4c4c4c;
  font-size: 14px;
  border-radius: 20px;
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  border: 1px solid #78acfb !important;
}

.email-tag-item>.email-button {
  background-color: #17A2B8;
  color: white;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-email {
  border: none;
  outline: none;
  flex: 1;
  font: inherit;
  padding: 0.5rem;
  min-width: 150px;
}

.react-confirm-alert-overlay {
  z-index: 9999;
}

.tab-data-model {
  background-color: white;
  margin-top: -14px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #dee2e6;
  margin-left: -2px;
}

.nav-link .active {
  border-color: #dee2e6;
}

.header-profile-empty-space {
  width: 100%;
  height: 20px;
  margin-top: -20px;
}

.reminder-tab-icon {
  border-bottom: 0px;
  margin: auto;
  display: flex;
  align-items: center
}

.active-tab-color {
  color: #0d6efd !important;
}


.sticky-table-container {
  overflow-x: auto;
}

.sticky-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.sticky-table th:first-child,
.sticky-table td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  /* Ensure it overlays other columns */
  background-color: white;
  /* Maintain visibility */
}

.print-btn {
  font-size: 19px;
  margin: 0px 5px;
  color: white;
  background: #333333;
  cursor: pointer;
}


@media print {
  /* body {
    -webkit-print-color-adjust: exact; 
    color-adjust: exact; 
  } */

  .print-table-wrapper {
    width: 100%;
    overflow: hidden;
  }

  .print-table {
    width: 100%;
    table-layout: fixed;
    font-size: 10px;
    /* Reduce font size for printing */
  }

  .print-table th,
  .print-table td {
    word-wrap: break-word;
    overflow: hidden;
    white-space: normal;
    padding: 5px;
    /* Reduce padding */
  }
}